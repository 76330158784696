import { CheckCircleIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { kitCalc } from "../backend/utils";
import jobTypes from "../data/jobTypes";
import Footer from "./footer";
import KitList from "./kitList";
import LineGroup from "./lineGroup";

const Main = () => {
  const defaultLine = { id: 1, amount: 0, name: "" };

  const [lines, setLines] = useState([defaultLine]);
  const [nextId, setNextID] = useState(2);
  const [output, setOutput] = useState("");
  const [linesChanged, setLinesChanged] = useState(false);
  const [jobsReady, setJobsReady] = useState(false);

  useEffect(() => {
    if (lines.length > 0) {
      const firstJobChanged = lines[0].amount !== 0 || lines[0].name !== "";

      const lineChanged = !(lines.length === 1);

      // console.log("firstJobChanged", firstJobChanged);
      // console.log("lineChanged", lineChanged);

      if (lineChanged || firstJobChanged) {
        setLinesChanged(true);
      } else {
        setLinesChanged(false);
      }
    }
  }, [lines]);

  useEffect(() => {
    let oneReady = false;

    lines.forEach((line) => {
      if (line.amount !== 0 && line.name !== "") {
        oneReady = true;
      }
    });

    if (oneReady) {
      setJobsReady(true);
    } else {
      setJobsReady(false);
    }
  }, [lines]);

  const getKitList = () => {
    const cleanedResults = cleanResults(lines);
    const results = kitCalc(jobTypes, cleanedResults);

    // Clean up lines
    let newLines = [...lines];
    newLines = newLines.filter((line) => line.amount !== 0 && line.name !== "");
    setLines(newLines);

    if (results.items.length > 0 && results.jobTypeTotals.length > 0) {
      setOutput(results);
    }
  };

  const clearKitList = () => {
    setOutput("");
  };

  const resetLines = () => {
    setLines([defaultLine]);
  };

  const addLine = () => {
    const newLines = [...lines];
    newLines.push({ id: nextId, amount: 0, name: "" });

    setLines(newLines);
    setNextID((prevNextId) => prevNextId + 1);
  };

  const deleteLine = (id) => {
    const newLines = [...lines].filter((job) => job.id !== id);
    setLines(newLines);
    clearKitList();
  };

  const onAmountChange = (value, id) => {
    const newLines = [...lines];
    const job = newLines.filter((job) => job.id === id)[0];
    job["amount"] = parseInt(value);
    setLines(newLines);
    clearKitList();
  };

  const onJobTypeChange = (e, id) => {
    const newLines = [...lines];
    const job = newLines.filter((job) => job.id === id)[0];
    job["name"] = e.target.value;
    setLines(newLines);
    clearKitList();
  };

  const isJobNameValid = (name) => {
    if (!name.length) return false;
    if (name === "-----") return false;
    return true;
  };

  const cleanResults = () => {
    const results = [...lines]
      .filter((job) => job.amount > 0 && isJobNameValid(job.name))
      .map(({ id, ...rest }) => rest);

    return results.reduce(
      ((map) => (r, a) => {
        map.set(
          a.name,
          map.get(a.name) || r[r.push({ name: a.name, amount: 0 }) - 1]
        );
        map.get(a.name).amount += a.amount;
        return r;
      })(new Map()),
      []
    );
  };

  const printProp = {
    "@media print": {
      display: "none",
    },
  };

  const noMargin = {
    "@media print": {
      marginTop: 0,
      marginBottom: 0,
    },
  };

  return (
    <Container my={50} sx={noMargin}>
      <Box sx={printProp}>
        <Heading>Kit List Generator </Heading>

        <Divider
          my={5}
          sx={{
            "@media print": {
              display: "none",
            },
          }}
        />
        <LineGroup
          lines={lines}
          addLine={addLine}
          deleteLine={deleteLine}
          onAmountChange={onAmountChange}
          onJobTypeChange={onJobTypeChange}
          resetLines={resetLines}
          linesChanged={linesChanged}
        />
        <Flex direction={["column", "row"]} gap={3}>
          <Button
            colorScheme="teal"
            onClick={getKitList}
            leftIcon={<CheckCircleIcon />}
            disabled={!jobsReady}
          >
            Get Kit List
          </Button>
          <Button
            colorScheme="red"
            onClick={clearKitList}
            leftIcon={<DeleteIcon />}
            variant="outline"
            disabled={!output}
          >
            Clear Kit List
          </Button>
        </Flex>
      </Box>
      <KitList data={output} />
      <Footer />
    </Container>
  );
};

export default Main;
