import {
  Box,
  Divider,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { titleCase } from "../backend/utils";

import React from "react";

const KitListItems = (props) => {
  const { items = [] } = props;

  if (!items.length) {
    return;
  }

  const cutMargin = {
    "@media print": {
      margin: "0 0 15px 0",
    },
  };

  return (
    <Box>
      <Heading as="h2" size="lg" color={"red.500"} mt={50}>
        Total required for all above jobs:
      </Heading>
      <Divider my={5} sx={cutMargin} />
      <TableContainer>
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Th>Item</Th>
              <Th>Amount</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item) =>
              Object.entries(item).map(([key, value], index) => {
                return (
                  <Tr key={index}>
                    <Td>{titleCase(key)}</Td>
                    <Td>{value}</Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default KitListItems;
