const jobTypes = [
  {
    id: 1,
    name: "Traditional Class",
    kit: {
      "background stands": 3,
      clipboard: 1,
      crossbar: 1,
      "curtain (singular)": 6,
      "floor dots": 5,
      "large clips": 8,
      "light stands": 2,
      lights: 2,
      trigger: 1,
      tripod: 1,
      umbrella: 2,
    },
  },

  {
    id: 2,
    name: "White Background Class",
    kit: {
      "background stands": 2,
      "light stands": 4,
      "winders & prongs (set)": 1,
      lights: 4,
      umbrella: 4,
      stool: 1,
      "floor dots": 1,
      tripod: 1,
      trigger: 1,
      clipboard: 1,
      "paper roll white": 1,
      scissors: 1,
      "floor brush": 1,
      tape: 1,
    },
  },
  {
    id: 3,
    name: "White Background Dance",
    kit: {
      "background stands": 2,
      "light stands": 4,
      "winders & prongs (set)": 1,
      lights: 4,
      umbrella: 4,
      tripod: 1,
      trigger: 1,
      clipboard: 1,
      "paper roll white": 1,
      scissors: 1,
      "floor brush": 1,
      tape: 1,
    },
  },
  {
    id: 4,
    name: "Green Screen",
    kit: {
      "background stands": 2,
      clipboard: 1,
      "green feet": 1,
      "light stands": 2,
      lights: 2,
      tape: 1,
      tripod: 1,
      umbrella: 2,
      "winders & prongs (set)": 1,
    },
  },
  {
    id: 5,
    name: "Prom",
    kit: {
      "background stands": 2,
      clipboard: 1,
      crossbar: 1,
      "curtain (singular)": 3,
      "large clips": 4,
      "light stands": 2,
      lights: 2,
      trigger: 1,
      umbrella: 2,
    },
  },
  {
    id: 6,
    name: "Individuals",
    kit: {
      "background stands": 1,
      "light stands": 1,
      "pop up background": 1,
      "tri reflector stand": 1,
      "tri reflector arms": 1,
      "mini floor stand": 1,
      "backlight modifier": 1,
      lights: 2,
      "beauty dish modifier": 1,
      stool: 1,
      tape: 1,
      "floor dots": 2,
      trigger: 1,
    },
  },
  {
    id: 7,
    name: "Perfect Kit",
    kit: {
      "Class photo poles & stands kit": 1,
      Curtains: 8,
      "Pika 200": 2,
      "400w Wired": 2,
      "Large BG Stands": 2,
      "Light Stands": 4,
      Umbrellas: 4,
      Octabox: 1,
      "Winders & prongs": 1,
      Trigger: 2,
      "Coloured floor dots": 5,
      "Green Feet": 1,
      Tape: 1,
      Squeaker: 1,
      "Tether lead & jerk stopper": 1,
      "Pop Up Grey BG": 1,
      "Tri reflector panels": 3,
      "Tri reflector arms": 1,
      "Tri reflector floor stand": 1,
      "Mini backlight stand": 1,
      "Backlight dish": 1,
      Scissors: 1,
      "Clip Board": 1,
      "Stand bag": 1,
      Rucksack: 1,
      Suitcase: 1,
      "Fold up step": 1,
      "Laptop ": 1,
      "Hard Drive": 1,
      "White bg": 1,
      "Green Screen": 1,
      Stool: 2,
      "Power lead": 3,
      "4 plug 10m Extension lead": 2,
      "AA Batteries": 2,
      "Spare bulb": 1,
      "Spare batteries for pika": 2,
      Clips: 8,
      "Tape measure": 1,
      "Artificial Grass": 2,
      "Grey Card": 1,
      "Pika Bracket": 1,
    },
  },
  {
    id: 8,
    name: "2024 INDIVIDUAL AND SIBLING",
    kit: {
      Laptop: 1,
      Camera: 1,
      "24-70 or 85mm Lens": 1,
      "50mm Lens (Backup)": 1,
      "Tether Lead": 1,
      Trigger: 1,
      "Sync Lead": 1,
      "Hard Drive": 1,
      "Jerk Stopper": 1,
      "10m Extension Lead": 1,
      "White Tape": 1,
      "Blue Tape": 1,
      "SD Card": 3,
      "AA Batteries": 2,
      "Coloured Dots": 2,
      Stools: 2,
      "Floor Mat": 1,
      "XL Stand": 1,
      "Stands (size appropriate)": 3,
      "Stand Bag": 1,
      "Backpack or Case": 1,
      "Large Background": 1,
      "Tri Reflector Panel": 3,
      "Tri Reflector Holder": 1,
      "Tri Reflector Stand": 1,
      "Pika Light": 2,
      "Wired Light": 1,
      "Power Cable": 1,
      "Pika Floor Stand": 1,
      "Mini Reflector Dish": 1,
      "Octagonal Softbox": 1,
      "Softbox Umbrella": 1,
    },
  },
];

export default jobTypes;
