// import jobTypes from './../data/jobTypes';

export const kitCalc = (jobTypes, jobList) => {
  const detail = kit_detail(jobList, jobTypes);
  const totals = kit_totals(detail);
  const result = create_results(detail, totals);
  return result;
};

const multiply_kit = (kitObject, amount) => {
  // Returns a JSON multiplied by the amount

  const kit = kitObject["kit"];

  let multiplied_kit = {};

  for (let key of Object.keys(kit)) {
    multiplied_kit[key] = kit[key] * amount;
  }

  return multiplied_kit;
};

const kit_detail = (jobList, jobTypes) => {
  // Complies an object of data that is multiplied by the amount of jobs of each type and returns an object

  const jobTypeTotals = [];

  for (const job of jobList) {
    const result = jobTypes.filter((_job) => _job.name === job.name)[0];
    const multiplied_kit = multiply_kit(result, job["amount"]);

    const new_obj = {
      name: job["name"],
      amount: job["amount"],
      kit: multiplied_kit,
    };

    jobTypeTotals.push(new_obj);
  }

  return jobTypeTotals;
};

const kit_totals = (jobTotals) => {
  // Takes and Array of Objects and returns an Object

  const kit_totals = {};

  for (const job of jobTotals) {
    const kit = job["kit"];

    for (let item of Object.keys(kit)) {
      const quantity = kit[item];

      if (item in kit_totals) {
        kit_totals[item] += quantity;
      } else {
        kit_totals[item] = quantity;
      }
    }
  }

  return kit_totals;
};

const create_results = (kitDetail, kitTotals) => {
  return { jobTypeTotals: [...kitDetail], items: [kitTotals] };
};

export function titleCase(str) {
  // https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
  // User Ulysse BN's answer
  let upper = true;
  let newStr = "";
  for (let i = 0, l = str.length; i < l; i++) {
    // Note that you can also check for all kinds of spaces  with
    // str[i].match(/\s/)
    if (str[i] === " ") {
      upper = true;
      newStr += str[i];
      continue;
    }
    newStr += upper ? str[i].toUpperCase() : str[i].toLowerCase();
    upper = false;
  }
  return newStr;
}
