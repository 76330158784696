import { AddIcon, RepeatIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Flex } from "@chakra-ui/react";
import Line from "./line";

const LineGroup = (props) => {
  const {
    lines,
    addLine,
    deleteLine,
    resetLines,
    linesChanged,
    onAmountChange,
    onJobTypeChange,
  } = props;

  const solo = lines.length === 1;

  return (
    <Box>
      <Flex direction={["column", "row"]} gap={3}>
        <Button
          colorScheme="teal"
          onClick={addLine}
          variant="outline"
          leftIcon={<AddIcon />}
        >
          Add Line
        </Button>
        <Button
          colorScheme="red"
          onClick={resetLines}
          leftIcon={<RepeatIcon />}
          variant="outline"
          disabled={!linesChanged}
        >
          Reset Lines
        </Button>
      </Flex>
      <Divider my={5} />
      {lines.map((line, index) => (
        <Line
          key={line.id}
          id={line.id}
          deleteLine={deleteLine}
          amount={line.amount}
          jobName={line.name}
          onAmountChange={onAmountChange}
          onJobTypeChange={onJobTypeChange}
          solo={solo}
          nth={index % 2}
        />
      ))}
      <Divider my={5} />
    </Box>
  );
};

export default LineGroup;
