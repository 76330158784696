import { Box } from "@chakra-ui/react";
import { Fragment } from "react";
import KitListItems from "./kitListItems";
import KitListJobTotals from "./kitListJobTotals";

const KitList = (props) => {
  const {
    data: { jobTypeTotals = [], items = [] },
  } = props;

  return (
    <Fragment>
      <Box>
        <KitListJobTotals jobTypeTotals={jobTypeTotals} />
        <KitListItems items={items} />
      </Box>
    </Fragment>
  );
};

export default KitList;
