import {
  Box,
  Divider,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { Fragment } from "react";
import { titleCase } from "../backend/utils";

const KitListJobTotals = (props) => {
  const { jobTypeTotals = [] } = props;

  if (!jobTypeTotals.length) {
    return;
  }

  const cutMargin = {
    "@media print": {
      margin: "0 0 15px 0",
    },
  };
  const printProp = {
    "@media print": {
      display: "none",
    },
  };

  return (
    <Box>
      <Divider my={5} sx={printProp} />
      <Heading as="h3" size="lg" color={"gray.500"}>
        Kit Required for Each Job Type:
      </Heading>
      {jobTypeTotals.map((jobType, index) => (
        <Fragment key={index}>
          <Heading as="h2" size="md" color={"gray.600"}>
            <Divider my={5} sx={cutMargin} />
            {jobType.amount} x {jobType.name}
            <Divider my={5} sx={cutMargin} />
          </Heading>

          <TableContainer>
            <Table variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th>Item</Th>
                  <Th>Amount</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.entries(jobType.kit).map(([key, value], index) => {
                  return (
                    <Tr key={index}>
                      <Td>{titleCase(key)}</Td>
                      <Td>{value}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Fragment>
      ))}
    </Box>
  );
};

export default KitListJobTotals;
