import { CheckCircleIcon, DeleteIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { Fragment } from "react";
import jobTypes from "../data/jobTypes";

const Line = (props) => {
  const { amount, jobName, onAmountChange, onJobTypeChange, solo, nth } = props;

  const lineOK = amount !== 0 && jobName !== "";

  const okProps = {
    height: "100%",
    position: "relative",
    bottom: "-2",
  };

  const okIcon = lineOK ? (
    <CheckCircleIcon color={"teal"} {...okProps} alignSelf={"center"} />
  ) : (
    <WarningIcon color={"red.400"} {...okProps} alignSelf={"center"} />
  );

  const evenColour = "";

  const oddColour = useColorModeValue("gray.100", "gray.700");

  let boxColour = nth ? oddColour : evenColour;

  return (
    <Fragment>
      <Box backgroundColor={boxColour} p={3} borderRadius={5} mb={["5", "0"]}>
        <Flex direction={["column", "row"]} gap={3}>
          {okIcon}
          <Box width={["100%", "20%"]}>
            <Text fontSize="xs"># of Jobs</Text>
            <NumberInput
              size="sm"
              min={0}
              value={amount}
              onChange={(value) => onAmountChange(value, props.id)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Box width={["100%", "66%"]}>
            <Text fontSize="xs">Choose a Job Type</Text>
            <Select
              size="sm"
              placeholder="-----"
              onChange={(e) => onJobTypeChange(e, props.id)}
              value={jobName}
            >
              {jobTypes.map((job) => (
                <option key={job.id} value={job.name}>
                  {job.name}
                </option>
              ))}
            </Select>
          </Box>
          {!solo && (
            <IconButton
              size="sm"
              alignSelf={"end"}
              width={["100%", "1"]}
              colorScheme={"red"}
              aria-label="Delete Line"
              icon={<DeleteIcon />}
              // bottom={["0", "-5"]}
              position="relative"
              onClick={() => {
                props.deleteLine(props.id);
              }}
            />
          )}
        </Flex>
      </Box>
    </Fragment>
  );
};

export default Line;
