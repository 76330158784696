import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Link,
  Text,
  useColorMode,
} from "@chakra-ui/react";

const Footer = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const printProp = {
    "@media print": {
      display: "none",
    },
  };

  return (
    <Box sx={printProp}>
      <Divider my={5} />
      <Flex justifyContent={"space-between"}>
        <Text color={"gray.500"}>
          <Link href="https://kainey.co.uk" isExternal>
            kainey.co.uk
          </Link>{" "}
          &copy; 2022
        </Text>
        <Button onClick={toggleColorMode} size="sm">
          {colorMode === "light" ? (
            <Icon as={MoonIcon} />
          ) : (
            <Icon as={SunIcon} />
          )}
        </Button>
      </Flex>
    </Box>
  );
};
export default Footer;
